import { Route, Routes } from "react-router-dom";
// import { Layout } from "./Layout";
// import { Detail } from "../pages/snp/detail/Detail";
// import { List } from "../pages/snp/list/List";
// import { Home } from "./Home";
// import { AboutTheProgram } from "../pages/other/AboutTheProgram";
// import { ResourcesForSNPs } from "../pages/other/ResourcesForSNPs";
// import { WhatIsModelOfCare } from "../pages/other/WhatIsModelOfCare";
// import { MySaved } from "../pages/other/MySaved";
// import { Timeline } from "../pages/other/Timeline";
// import { Trainings } from "../pages/other/Trainings";
// import { GetHelp } from "../pages/other/GetHelp";
// import { NotFound } from "../pages/NotFound";
// import { Scoringguidelines } from "../pages/other/Scoringguidelines";
import React from "react";
const DetailAsync = React.lazy(() => import("../pages/snp/detail/Detail").then((module) => ({ default: module.Detail })));
const ListAsync = React.lazy(() => import("../pages/snp/list/List").then((module) => ({ default: module.List })));
const HomeAsync = React.lazy(() => import("./Home").then((module) => ({ default: module.Home })));
const AboutTheProgramAsync = React.lazy(() => import("../pages/other/AboutTheProgram").then((module) => ({ default: module.AboutTheProgram })));
const ResourcesForSNPsAsync = React.lazy(() => import("../pages/other/ResourcesForSNPs").then((module) => ({ default: module.ResourcesForSNPs })));
const WhatIsModelOfCareAsync = React.lazy(() => import("../pages/other/WhatIsModelOfCare").then((module) => ({ default: module.WhatIsModelOfCare })));
const MySavedAsync = React.lazy(() => import("../pages/other/MySaved").then((module) => ({ default: module.MySaved })));
const TimelineAsync = React.lazy(() => import("../pages/other/Timeline").then((module) => ({ default: module.Timeline })));
const TrainingsAsync = React.lazy(() => import("../pages/other/Trainings").then((module) => ({ default: module.Trainings })));
const TrainingsArchiveAsync = React.lazy(() => import("../pages/other/Trainings-Archive").then((module) => ({ default: module.TrainingsArchive })));
const GetHelpAsync = React.lazy(() => import("../pages/other/GetHelp").then((module) => ({ default: module.GetHelp })));

const ScoringguidelineslatestAsync = React.lazy(() =>
  import("../pages/other/Scoringguidelines2025").then((module) => ({ default: module.Scoringguidelines2024 }))
);
const LayoutAsync = React.lazy(() => import("./Layout").then((module) => ({ default: module.Layout })));
const NotFoundAsync = React.lazy(() => import("../pages/NotFound").then((module) => ({ default: module.NotFound })));
export function Main() {
  return (
    <Routes>
      <Route path="/*" element={<LayoutAsync />}>
        <Route index element={<HomeAsync />} />
        <Route path="snp/detail/:id/:year" element={<DetailAsync />} />
        <Route path="snp/list" element={<ListAsync />} />
        <Route path="about-the-program" element={<AboutTheProgramAsync />} />
        <Route path="resources-for-snps" element={<ResourcesForSNPsAsync />} />
        <Route path="what-is-model-of-care" element={<WhatIsModelOfCareAsync />} />
        <Route path="my-saved" element={<MySavedAsync />} />
        {/* <Route path="scoring-guidelines-2023" element={<Scoringguidelines2023Async />} /> */}
        <Route path="scoring-guidelines-latest" element={<ScoringguidelineslatestAsync />} />
        {/* <Route path="scoring-guidelines" element={<ScoringguidelinesAsync />} /> */}
        <Route path="timeline" element={<TimelineAsync />} />
        <Route path="trainings" element={<TrainingsAsync />} />
        <Route path="trainingsarchive" element={<TrainingsArchiveAsync />} />
        <Route path="get-help" element={<GetHelpAsync />} />
        <Route path="*" element={<NotFoundAsync />} />
      </Route>
    </Routes>
  );
}
